import PhoneCountryType from "../../../../../types/PhoneCountry.type.ts";
import Phone from "./Phone.tsx";


interface PhoneFieldProps {
    className?: string
    label?: string
    htmlId: string,
    labelClassName?: string,
    loadingClass?: string;
    phoneCountryObject: PhoneCountryType,
    setPhoneNumber: (value: string) => void
    setPhoneCountryObject: (value: PhoneCountryType) => void,
    phoneNumber: string,
    onBlur?: (any) => any,
    passengerDataUpdating?: boolean
    setValidState?: () => void,
    showError?: boolean,
    errorMessage?: string,
}

function PhoneField(props: PhoneFieldProps) {
    const {
        className = "",
        label,
        labelClassName = "",
        htmlId,
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        loadingClass = '',
        onBlur = () => {
        },
        passengerDataUpdating,
        setValidState = () => {
        },
        showError,
        errorMessage,
    } = props

    return (
        <div className={`phone-container ${className}`}>
            {label && (
                <label htmlFor={htmlId} className={labelClassName}>
                    {label}
                </label>
            )}
            <Phone
                htmlId={htmlId}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneCountryObject={phoneCountryObject}
                setPhoneCountryObject={setPhoneCountryObject}
                passengerDataUpdating={passengerDataUpdating}
                onBlur={onBlur}
                loadingClass={loadingClass}
                setValidState={setValidState}
                showError={showError}
                errorMessage={errorMessage}
            />
        </div>
    )
}

export default PhoneField
import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import useDisplayDuration from "../hooks/useDisplayDuration.ts";
import useSearchResultsTripInfo from "../hooks/useSearchResultsTripInfo.ts";
import PillLabel from "../../../../../components/generic/PillLabel.tsx";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {setRefreshResults} from "../../../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import Card from "../../../../../components/generic/Card.tsx";
import TripInfoData from "./TripInfoData.tsx";

interface SearchResultsInfoProps {
    label: string,
    duration: number,
    pickUpInfo: any,
    dropOffInfo: any
    isRoundTrip?: boolean
}

const TripInfo = (props: SearchResultsInfoProps) => {
    const {
        label,
        duration,
        dropOffInfo,
        pickUpInfo,
        isRoundTrip
    } = props

    const durationLabel = useDisplayDuration(duration)
    const {
        departure,
        destination,
        startDateDisplay,
        returnDateDisplay,
        returnReturnDateDisplay,
        returnStartDateDisplay
    } = useSearchResultsTripInfo(duration)

    const {
        lastSearchInvalidDeparture,
        lastSearchInvalidDestination,
    } = useFormPlaceValidator()

    const departureDisplay = pickUpInfo?.isAirport ? pickUpInfo?.pickUpPlace : departure;
    const departureValid = pickUpInfo?.isAirport ? !pickUpInfo?.isAirport : lastSearchInvalidDeparture;

    const destinationDisplay = dropOffInfo?.isAirport ? dropOffInfo?.dropOffPlace : destination;
    const destinationValid = dropOffInfo?.isAirport ? !dropOffInfo?.isAirport : lastSearchInvalidDestination;

    return (
        <Card>
            <CollapsableComponent
                label={label}
                wrapper
                hrClassName={'gb--divider__hr'}
            >
                <TripInfoData
                    durationLabel={durationLabel}
                    startDateDisplay={startDateDisplay}
                    returnDateDisplay={returnDateDisplay}
                    departureDisplay={departureDisplay}
                    destinationDisplay={destinationDisplay}
                    departureValid={departureValid}
                    destinationValid={destinationValid}
                />
                {isRoundTrip && (
                    <>
                        <hr className="gb--divider__hr"/>
                        <TripInfoData
                            durationLabel={durationLabel}
                            startDateDisplay={returnStartDateDisplay!}
                            returnDateDisplay={returnReturnDateDisplay!}
                            departureDisplay={destinationDisplay}
                            destinationDisplay={departureDisplay}
                            departureValid={destinationValid}
                            destinationValid={departureValid}
                        />
                    </>

                )}
            </CollapsableComponent>
        </Card>
    )
}

export default TripInfo

import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";

interface TripInfoDataProps {
    durationLabel: string,
    startDateDisplay: string,
    returnDateDisplay: string,
    departureDisplay: string,
    destinationDisplay: string,
    departureValid: boolean,
    destinationValid: boolean
}

function TripInfoData (props: TripInfoDataProps) {
    const {
        durationLabel,
        startDateDisplay,
        returnDateDisplay,
        departureDisplay,
        destinationDisplay,
        departureValid,
        destinationValid
    } = props;

    return (
        <Column>
            <div className="gb--booking__date">{startDateDisplay}</div>
            <Row
                className="gb--booking__location"
                justify
            >
                <div className={`${departureValid ? 'gb--booking__location-label' : ''}`}>
                    {departureDisplay}
                </div>
            </Row>
            <Row className="gb--booking__info">
                <div className="gb--booking__direction">
                    <hr className="gb--booking__connection"/>
                </div>
                <div className="gb--booking__estimate">
                    {durationLabel}
                </div>
            </Row>
            <div className="gb--booking__date">{returnDateDisplay}</div>
            <Row
                className="gb--booking__location"
                justify
            >
                <div className={`${destinationValid ? 'gb--booking__location-label' : ''}`}>
                    {destinationDisplay}
                </div>
            </Row>
        </Column>
    )
}

export default TripInfoData
import {Row} from "@getbybus/gbb-green";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import useIsTablet from "../../../hooks/selector/useIsTablet.ts";
import TripInfo from "../features/tripInfo/components/TripInfo.tsx";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import useGetSearchResultsVehiclesData from "../hooks/useGetSearchResultsVehiclesData.ts";
import useNavigateToContactForm from "../../../routing/navigation/useNavigateToContactForm.ts";
import shouldNavigateToContactForm from "../functions/shouldNavigateToContactForm.ts";
import SearchResults from "./SearchResults.tsx";
import useGetMozioSearchResults from "../features/mozioResults/hooks/useGetMozioSearchResults.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import NoResults from "./NoResults.tsx";
import useClearBookingFormOnNewSearch from "../../../layout/form/hooks/useClearBookingFormOnNewSearch.ts";
import {useEffect} from "react";
import {useRef} from "react";
import openDialog from "../../../features/dialog/functions/openDialog.ts";
import VehicleCardLoader from "../../../components/generic/loaders/VehicleCardLoader.tsx";
import {BreadcrumbLoader} from "../features/loader/components/BreadcrumbLoader.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";

const SearchResultsWrapper = () => {
    const isTablet = useIsTablet()
    const isMobile = useIsMobile()
    const isSmallScreen = isMobile || isTablet
    const {navigateToContactForm} = useNavigateToContactForm()
    const {clearBookingFormOnNewSearch} = useClearBookingFormOnNewSearch();
    const sidebarHeader = useTranslation('booking.sidebar.header') as string;

    const dialogRef = useRef<HTMLDialogElement>(null)
    const onIncompleteSearchFormData = () => {
        openDialog(dialogRef)
    }

    const {
        isUninitialized,
        isLoading,
        error,
        data,
        originalArgs
    } = useGetSearchResultsVehiclesData()

    const {
        mozioIsUninitialized,
        mozioIsLoading,
        mozioVehiclesData,
    } = useGetMozioSearchResults(isLoading, error, data, originalArgs);

    const {
        unsuccessfulMozioFetchCount,
    } = useAppSelector((state) => (state.searchResults))

    useEffect(() => {
        clearBookingFormOnNewSearch();
    }, []);

    const shouldDisplayLoader = isUninitialized || isLoading || data?.data?.integrations?.mozio?.moreComing;

    // the results are loaded, there is an error, and there is a flag in response which indicates that we should redirect users to Contact form
    if (shouldNavigateToContactForm(isLoading, error)) {
        navigateToContactForm(error as FetchBaseQueryError)
    }

    if (!shouldDisplayLoader && (!data?.data?.gbt?.vehicles || !Object.keys(data?.data?.gbt?.vehicles).length)) {
        return (
            <NoResults/>
        )
    }

    return (
        <>
            {shouldDisplayLoader && (
                <BreadcrumbLoader/>
            )}
            <Row
                justify
                reverse
            >

                {!isSmallScreen && !shouldDisplayLoader && (
                    <div className="gb--booking__wrapper gb--vehicle__preview">
                        <TripInfo
                            label={sidebarHeader}
                            duration={data?.data?.duration}
                            pickUpInfo={data?.data?.pickupInfo}
                            dropOffInfo={data?.data?.dropOffInfo}
                            isRoundTrip={data?.data?.isRoundTrip}
                        />
                    </div>
                )}
                {!isSmallScreen && shouldDisplayLoader && (
                    <div className='gb--vehicle__preview'>
                        <VehicleCardLoader/>
                    </div>
                )}
                <SearchResults
                    data={data}
                    isLoading={isLoading}
                    error={error}
                    mozioVehiclesData={mozioVehiclesData}
                    mozioIsUninitialized={mozioIsUninitialized}
                    mozioIsLoading={mozioIsLoading}
                    unsuccessfulMozioFetchCount={unsuccessfulMozioFetchCount}
                    originalArgs={originalArgs}
                    dialogRef={dialogRef}
                    onIncompleteSearchFormData={onIncompleteSearchFormData}
                    shouldDisplayLoader={shouldDisplayLoader}
                />
            </Row>
        </>
    )
}
export default SearchResultsWrapper

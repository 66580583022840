import PhoneCountryType from "../../../../../types/PhoneCountry.type.ts";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import useUpdatePhoneNumber from "../hooks/useUpdatePhoneNumber.ts";
import getHandlePhoneInput from "../functions/getHandlePhoneInput.ts";
import useTranslation from "../../../../translation/hooks/useTranslation.ts";
import ClickOutsideAwareComponent from "../../../../../components/generic/ClickOutsideAwareComponent.tsx";
import CountrySelect from "../../../../countrySelect/components/CountrySelect.tsx";
import SelectedCountryIcon from "../../../../countrySelect/components/SelectedCountryIcon.tsx";
import {Notification} from "@getbybus/gbb-green";

interface PhoneInputProps {
    loadingClass?: string;
    phoneCountryObject: PhoneCountryType,
    setPhoneNumber: (value: string) => void
    setPhoneCountryObject: (value: PhoneCountryType) => void,
    phoneNumber: string,
    onBlur?: (any?) => any,
    passengerDataUpdating?: boolean
    setValidState?: () => void,
    htmlId?: string,
    showError?: boolean,
    errorMessage?: string,
}

function Phone(props: PhoneInputProps) {
    const {
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        loadingClass = '',
        onBlur = () => {
        },
        passengerDataUpdating,
        setValidState = () => {
        },
        htmlId,
        showError,
        errorMessage,
    } = props

    const {
        value: phoneCountrySelectActive,
        set: setPhoneCountrySelect,
        onChange: togglePhoneCountrySelect,
    } = useToggle()

    const closePhoneCountrySelect = () => {
        setPhoneCountrySelect(false)
    }

    useUpdatePhoneNumber({
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        closePhoneCountrySelect,
        setValidState,
    })
    const handlePhoneInput = getHandlePhoneInput(setPhoneNumber)

    return (
        <>
            <div className={`phone-input ${loadingClass}`}>
                <div
                    className="gb--row gb--row-middle gb--row-justify gb--input__adornment"
                    onClick={togglePhoneCountrySelect}
                >
                    <SelectedCountryIcon selectedCountry={phoneCountryObject}/>
                </div>
                <input
                    id={htmlId}
                    placeholder={useTranslation('booking.form.phoneNumber') as string}
                    value={phoneNumber}
                    onChange={handlePhoneInput}
                    autoComplete="tel"
                    type="tel"
                    onBlur={onBlur}
                    disabled={passengerDataUpdating}
                />
            </div>
            {phoneCountrySelectActive && (
                <ClickOutsideAwareComponent onClickOutside={closePhoneCountrySelect}>
                    <CountrySelect
                        setPhoneCountryObject={(country) => {
                            setPhoneCountryObject(country)
                            closePhoneCountrySelect()
                        }}
                    />
                </ClickOutsideAwareComponent>
            )}
            {showError && (
                <Notification isLarge notificationType="red">
                    {errorMessage}
                </Notification>
            )}
        </>
    )
}

export default Phone
import {useToggle} from "@getbybus/gbb-green";
import {useState} from "react";
import datasetParser from "../../../../../../utils/dataset/datasetParser.ts";
import {getTranslatedRoute} from "../../../../../../routing/functions/getTranslatedRoute.ts";
import Column from "../../../../../../features/containers/components/Column.tsx";
import IconStarRating from "../../../../../../components/generic/icons/IconStarRating.tsx";
import Translation from "../../../../../../features/translation/components/Translation.tsx";
import PillLabel from "../../../../../../components/generic/PillLabel.tsx";
import IconSuitcaseV2 from "../../../../../../components/generic/icons/IconSuitcaseV2.tsx";
import Row from "../../../../../../features/containers/components/Row.tsx";
import IconPax from "../../../../../../components/generic/icons/IconPax.tsx";
import useLocale from "../../../../../../hooks/selector/useLocale.ts";
import StaticDatePicker from "../../../components/StaticDatePicker.tsx";
import StaticIncompleteModal from "./StaticIncompleteModal.tsx";
import {calculateRatingValue} from "../functions/calculateRatingValue.ts";
import {splitPrice} from "../functions/splitPrice.ts";

interface VehicleCardProps {
    vehicleGroup: number
    vehicleGroupType: string
    vehicleImagePath: string
    vehicleMinPaxCapacity: number
    vehicleMaxPaxCapacity: number
    vehicleRating: number,
    vehicleModelMake: string
    vehicleRatingCount: number
    vehiclePrice?: string
}

const VehicleCard = (props: VehicleCardProps) => {
    const {
        vehicleGroup,
        vehicleGroupType,
        vehicleImagePath,
        vehicleMinPaxCapacity,
        vehicleMaxPaxCapacity,
        vehicleRating,
        vehicleModelMake,
        vehicleRatingCount,
        vehiclePrice,
    } = props

    const {
        searchUrl: {
            fromId,
            fromSlug,
            toId,
            toSlug,
            pickUpDate,
            // noOfPersons
        },
    } = datasetParser({id: "vehicle-group-portal"})

    const locale = useLocale()
    const {getPath} = getTranslatedRoute()
    const bookingSlugTranslation = getPath("booking")
    const bookingData = {
        locale: locale,
        booking: bookingSlugTranslation,
        fromSlug: `${fromSlug}-${fromId}`,
        toSlug: `${toSlug}-${toId}`,
        passengers: vehicleMinPaxCapacity,
    };

    const price = vehiclePrice ? splitPrice(vehiclePrice) : null

    const {value, set} = useToggle()
    const [isOpen, setIsOpen] = useState(false)

    const handleMouseOver = () => {
        set(true)
    }

    const handleMouseOut = () => {
        set(false)
    }

    const setOpen = () => setIsOpen(!isOpen)

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    return (
        <>
            {isOpen && (
                <StaticIncompleteModal
                    closeModal={setOpen}
                    isOpen={isOpen}
                    params={bookingData}
                />
            )}
            <div
                key={vehicleGroup}
                className="card__vehicle--sts"
                onClick={setOpen}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <Column
                    justify
                    center
                    className="gb--card card__vehicle"
                >
                    <Column center className={'card__header'}>
                        <div className={'card__ratings'}>
                            <p>({vehicleRatingCount})</p>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <IconStarRating
                                    width={'16'}
                                    height={'16'}
                                    color={'#7ed321'}
                                    key={index}
                                    ratingValueWidth={calculateRatingValue(vehicleRating, index)}
                                    fill={'#ddd'}
                                />
                            ))}
                        </div>
                        <p className="gb--emphasize card__title">
                            {vehicleGroupType}
                        </p>
                        <p className='card__subtitle'>
                            {vehicleModelMake && vehicleModelMake.length > 1 && (
                                <>
                                    {vehicleModelMake}
                                    {' '}
                                    <Translation translationKey={'booking.chooseVehicle.orSimilar'}/>
                                </>
                            )}
                        </p>
                    </Column>
                    <Column
                        wrap
                        className="vehicle-group__desc"
                    >
                        <img
                            width="auto"
                            height="auto"
                            src={vehicleImagePath}
                            alt={vehicleGroup}
                            onError={handleImageError}
                        />
                        <Row justify className={'card__perks'}>
                            <PillLabel
                                color='blue'
                                label={
                                    <Row className={'card__capacity'}>
                                        <div>
                                            <IconPax />
                                            {vehicleMinPaxCapacity} - {vehicleMaxPaxCapacity}
                                        </div>
                                        &#9679;
                                        <div>
                                            <IconSuitcaseV2 />
                                            {vehicleMaxPaxCapacity}
                                        </div>
                                    </Row>
                                }
                            />
                        </Row>
                    </Column>
                    <Row className="card__action">
                        {price && (
                            <Column>
                                <p className="vehicle-group__from">
                                    <Translation translationKey={'stationToStation.startingFrom'}/>
                                </p>
                                <span className="vehicle-group__price">
                                    <span className="euro-icon">
                                        &#8364;
                                    </span>
                                    {price.integer},
                                    <sup>
                                        {price.fraction}
                                    </sup>
                                </span>
                            </Column>
                        )}
                        <button
                            type="button"
                            className={`btn btn--blue-darker `}
                        >
                            <Translation translationKey={`stationToStation.mozioVehicles.cta`}/>
                        </button>
                    </Row>
                </Column>
            </div>
        </>
    )
}

export default VehicleCard

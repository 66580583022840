import Translation from "../../../../../features/translation/components/Translation.tsx";
import IconStarRating from "../../../../../components/generic/icons/IconStarRating.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Tooltip from "../../../../../features/tooltip/components/Tooltip.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {truncate} from "lodash";

interface VehicleCardInfoProps {
    ratingCount: number;
    type: string;
    modelMake: string | null;
    rating: string;
    id: number;
}

export const VehicleCardInfo = (props: VehicleCardInfoProps) => {
    const {
        id,
        type,
        rating,
        modelMake,
        ratingCount
    } = props;

    const newLabel = useTranslation('email.default.new') as string;
    const orSimilarText = useTranslation('booking.chooseVehicle.orSimilar') as string;

    const modelMakeText = modelMake ? truncate(`${modelMake} ${orSimilarText}`, {length: 40, omission: '...'}) : '';

    return (
        <Column alignStart className={'gb--vehicle-info'}>
            {!ratingCount && (
                <div className={'gb--vehicle-new'}>
                    <h3>
                        {newLabel.toUpperCase()}
                    </h3>
                </div>
            )}
            <div>
                <h2>
                    {type}
                </h2>
            </div>
            <div>
                {modelMake && modelMake.length > 1 && (
                    <p className={'gb--vehicle-subtitle'}>
                        {modelMakeText}
                    </p>
                )}

            </div>
            {!!ratingCount && (
                <Tooltip
                    className={'gb--vehicle__tooltip'}
                    direction={"bottom"}
                    tooltipContent={<Translation translationKey={'booking.chooseVehicle.ratings'}/>}
                >
                    <div className={'card__ratings'}>
                        <IconStarRating
                            width={'16'}
                            height={'16'}
                            color={'#7ed321'}
                            key={`${id}-rating`}
                            ratingValueWidth={100}
                            fill={'#ddd'}
                        />
                        <p>{` ${Number(rating).toFixed(1)}/5(${ratingCount})`}</p>
                    </div>
                </Tooltip>
            )}
        </Column>
    )
}
import {useState} from 'react';
import IconSearch from "../generic/icons/IconSearch.tsx";

interface AutocompleteProps {
    onChange: (any?) => any,
    suggestions: any[],
    placeholder: string,
    className: string,
    value?: any,
    searchIcon?: boolean,
}

function Autocomplete(props: AutocompleteProps) {
    const {
        onChange,
        suggestions,
        placeholder,
        className,
        value,
        searchIcon
    } = props

    const [selectedItem, setSelectedItem] = useState(0)
    const [visible, setVisible] = useState(false)

    // TODO: finish implementing generic autocomplete
    // onBlur = () => {
    //     setTimeout(() => {
    //     this.setState({
    //         visible: false
    //     })}, 100)
    //     // if (visible) {
    //     //     // updateValue(testItems[selectedItem].label)
    //     //     // onChange(testItems[selectedItem])
    //     //     // selectItem(testItems[selectedItem])
    //     //     // setVisible(false)
    //     // }
    // }
    //
    // onFocus = () => {
    //     this.setState({
    //         visible: true
    //     })
    // }

    const handleKeyDown = (e) => {
        const {keyCode} = e
        let {onChange, suggestions} = props

        // Tab || enter
        if (keyCode === 9 || keyCode === 13) {
            e.target.blur();
        }
        // Escape
        if (keyCode === 27) {
            onChange('')
        }
        // Arrow left
        if (keyCode === 37) {
            e.target.blur();
        }
        // Arrow right
        if (keyCode === 39) {
            e.target.blur();
        }
        // Arrow up
        if (keyCode === 38) {
            if (selectedItem === 0) {
                setSelectedItem(suggestions.length)
            } else {
                setSelectedItem(suggestions.length - 1)
            }
        }
        // Arrow down
        if (keyCode === 40) {
            if (selectedItem === suggestions.length) {
                setSelectedItem(0)
            } else {
                setSelectedItem(selectedItem + 1)
            }
        }
    }

    return (
        <div className={className}>
            <div className={'gb--row gb--row-middle gb--input-field-with-icon'}>
                {searchIcon && (
                    <span className="gb--input__adornment">
                        <IconSearch/>
                    </span>
                )}
                <input
                    placeholder={placeholder}
                    onChange={event => onChange(event.target.value)}
                    // onFocus={() => this.onFocus()}
                    value={value}
                    autoFocus
                    // onBlur={() => this.onBlur()}
                    onKeyDown={(e) => handleKeyDown(e)}
                />
            </div>
            <div className={'gb--autocomplete-suggestions'}>
                {suggestions.map((suggestion) => (suggestion))}
            </div>
        </div>
    )
}

export default Autocomplete;
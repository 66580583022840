import React from 'react';
import IconChevron from "../../../components/generic/icons/IconChevron.tsx";
import IconGlobeWithFlag from "../../../components/generic/icons/IconGlobeWithFlag.tsx";
import NationalityType from "../../../types/Nationality.type.ts";
import CountryFlag from "./CountryFlag.tsx";


interface SelectedCountryIconProps {
    selectedCountry: NationalityType | string,
}

function SelectedCountryIcon({selectedCountry}: SelectedCountryIconProps) {
    if (typeof (selectedCountry) !== "string" && selectedCountry?.isoCode) {
        return (
            <>
                <CountryFlag isoCode={selectedCountry.isoCode}/>
                <IconChevron fill="#070c63" width="30" height="15"/>
            </>
        )
    }

    return (
        <>
            <IconGlobeWithFlag height="25"/>
            <IconChevron fill="#070c63" width="30" height="15"/>
        </>
    )
}

export default SelectedCountryIcon;
import {PaymentElement} from '@stripe/react-stripe-js';
import {Notification} from "@getbybus/gbb-green";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useGetPaymentElementOptions from "../hooks/useGetPaymentElementOptions.ts";
import useStripeCheckoutForm from "../hooks/useStripeCheckoutForm.ts";

const CheckoutForm = () => {
    const {
        paymentElementOptions,
    } = useGetPaymentElementOptions()

    const {
        handleSubmit,
        stripeError,
        loader,
    } = useStripeCheckoutForm()

    return (
        <form onSubmit={handleSubmit}>
            {stripeError && (
                <Notification
                    style={{padding: "1rem", margin: "1rem 0", textAlign: "start"}}
                    isLarge
                    notificationType={"orange"}
                >
                    {stripeError}
                </Notification>
            )}
            <PaymentElement
                className='gb--stripe'
                options={paymentElementOptions}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                {loader ? (
                    <div className='btn btn--blue-darker return-button-submit'>
                        <LoaderButton width='18' height='18'/>
                    </div>
                ) : (
                    <button className='btn btn--blue-darker btn--mobile return-button-submit' type="submit">
                        Submit
                    </button>
                )}
            </div>
        </form>
    );
};

export default CheckoutForm;
import {useEffect, useState} from "react";
import Translation from "../../translation/components/Translation.tsx";
import useTimeout from "../../../hooks/effects/useTimeout.ts";


interface FadingErrorProps {
    isError: boolean | null,
    showError: any, // any changeable value, for example, timestamp
    errorMessageTranslationKey?: string,
}

function FadingError({isError, showError, errorMessageTranslationKey}: FadingErrorProps) {
    const [visible, setVisible] = useState(false)
    const handleVisibility = () => setVisible(false)
    useTimeout(handleVisibility, 3000, [showError])
    const setVisibleOnSubmit = () => {
        if (showError) {
            setVisible(true)
        }
    }
    useEffect(setVisibleOnSubmit, [showError])

    if (isError && visible) {
        return (
            <div
                className="popover fade top in"
                style={{top: "110%"}}
            >
                <div className="arrow" style={{left: "50%"}}/>
                <div className="popover-content">
                    <Translation translationKey={errorMessageTranslationKey ?? "base.validation.pleaseEnterTheRequiredFieldsFirst"}/>
                </div>
            </div>
        );
    }

    return null
}

export default FadingError;
import {useState} from 'react';
import PhoneCountryType from "../../../types/PhoneCountry.type.ts";
import useTranslation from "../../translation/hooks/useTranslation.ts";
import filterByKeys from "../../../utils/array/filterByKeys.ts";
import Autocomplete from "../../../components/utility/Autocomplete.tsx";
import countryData from '../data/countryData.json';
import ImageLabelField from "../../../components/image/ImageLabelField.tsx";


interface CountrySelectProps {
    handler?: (any?) => any,
    setPhoneCountryObject?: (value: PhoneCountryType) => void,
}

function CountrySelect({handler, setPhoneCountryObject}: CountrySelectProps) {
    const [filteredCountries, setFilteredCountries] = useState(
        filterByKeys(countryData, '', ['isoCode', 'dialCode', 'name'])
    )

    const updateValue = (value) => {
        setFilteredCountries(filterByKeys(countryData, value, ['isoCode', 'dialCode', 'name']))
    }

    const handleCountrySelect = (country) => () => {
        if (setPhoneCountryObject) {
            setPhoneCountryObject(country)
        }
    }

    const renderCountries = () => filteredCountries.map((country, index) => {
        const {isoCode, name} = country

        return (
            // TODO change this into TS functional component
            <ImageLabelField
                imageHeight={50}
                imageUrl={`/public/country_flags/${isoCode.toLowerCase()}.png`}
                label={name}
                key={isoCode}
                index={index}
                onClickHandler={handler ? handler(country) : handleCountrySelect(country)}
            />
        )
    })

    return (
        <Autocomplete
            className="gb--country-select-container"
            suggestions={renderCountries()}
            onChange={updateValue}
            placeholder={useTranslation('booking.form.selectCountry') as string}
        />
    );
}

export default CountrySelect
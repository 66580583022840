import useBookingInfoInput from "../features/bookingInfoInputField/hooks/useBookingInfoInput.ts"
import {CountryType, emptyCountryObject} from "@getbybus/gbb-green"
import {useEffect, useState} from "react"
import useTranslation from "../../../features/translation/hooks/useTranslation.ts"
import {setContactFormData, setCountry, setCountryCode, setIsoCode} from "../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import {CountryCode, parsePhoneNumber} from "libphonenumber-js/max";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {validatePhone} from "./validatePhone.ts";
import {useScroll} from "../../../features/scroll/hooks/useScroll.ts";
import isEqual from "lodash/isEqual";

export const usePhone = () => {
    const {executeScroll, elRef} = useScroll({})
    const dispatch = useAppDispatch()
    const {
        country,
        countryCode,
        isoCode,
        submitTimestamp,
    } = useAppSelector((state) => state?.bookingForm);

    const {
        value: phone,
        displayError,
        errorMessage,
    } = useBookingInfoInput({
        formType: "contactInformationForm",
        fieldValidationMethod: () => validatePhoneNumber(),
        field: "phoneNumber",
    })

    const [countryPhone, setCountryPhone] = useState<CountryType>(emptyCountryObject)

    const errorMessagePhone = useTranslation(errorMessage) as string

    /**
     * Validates the phone number, checks if the phone number is valid and possible
     * @param phoneNumberString - the phone number, can be passed or uses the phone object
     */
    const validatePhoneNumber = (phoneNumberString: string = phone) => {
        if (validatePhone(phoneNumberString, countryPhone)) {
            const phoneNumber = parsePhoneNumber(phoneNumberString, countryPhone.isoCode as CountryCode)
            return !!phoneNumber.country && phoneNumber.isValid() && phoneNumber.isPossible()
        }

        return false
    }

    /**
     * Updates the phone number in the store
     * @param value - the phone number
     * @param validatePhone - if the phone number should be validated
     */
    const setPhoneHandler = (value: string, validatePhone: boolean = true) => {
        if (value.includes(countryPhone.dialCode)) {
            dispatch(
                setContactFormData({
                    value,
                    displayError: validatePhone && !validatePhoneNumber(value),
                    isValid: validatePhoneNumber(value),
                    type: "phoneNumber",
                }),
            )
        } else {
            setCountryPhone(emptyCountryObject)
            dispatch(setCountry(country.name))
            dispatch(setCountryCode(country.dialCode))
            dispatch(setIsoCode(country.isoCode))
            dispatch(
                setContactFormData({
                    value,
                    displayError: validatePhone && !validatePhoneNumber(value),
                    isValid: validatePhoneNumber(value),
                    type: "phoneNumber",
                }),
            )
        }
    }

    useEffect(() => {
        if (country, isoCode, countryCode) {
            setCountryPhone({
                name: country,
                dialCode: countryCode,
                isoCode,
            })
        }
    }, []);

    useEffect(() => {
        if (!validatePhoneNumber()) {
            executeScroll();
        }
    }, [submitTimestamp]);

    /**
     * Updates the country code, also updates the phone number prefix if the country code changes
     * @param country
     */
    const setCountryPhoneHandler = (country: CountryType) => {
        if (countryPhone.dialCode && countryPhone.dialCode !== country.dialCode && phone.includes(countryPhone.dialCode)) {
            setPhoneHandler(phone.replace(countryPhone.dialCode, country.dialCode), true)
        } else if (!phone.includes(country.dialCode)) {
            setPhoneHandler(country.dialCode + phone, phone.length < 0)
        }

        setCountryPhone(country)
        dispatch(setCountry(country.name))
        dispatch(setCountryCode(country.dialCode))
        dispatch(setIsoCode(country.isoCode))
    }

    const setValidState = () => {
        dispatch(
            setContactFormData({
                value: phone,
                displayError: !validatePhoneNumber(),
                isValid: validatePhoneNumber(),
                type: "phoneNumber",
            }),
        )
    }

    return {
        elRef,
        phone,
        displayError,
        countryPhone,
        setValidState,
        submitTimestamp,
        setPhoneHandler,
        setCountryPhone,
        errorMessagePhone,
        validatePhoneNumber,
        setCountryPhoneHandler
    }
}

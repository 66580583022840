import IconPax from "../../../../components/generic/icons/IconPax.tsx"
import IconEmail from "../../../../components/generic/icons/bookingIcons/IconEmail.tsx"
import Row from "../../../../features/containers/components/Row.tsx"
import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import BookingInfoInputField from "../bookingInfoInputField/components/BookingInfoInputField.tsx"
import validateEmail from "../../../../utils/validation/validateEmail.ts"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import {isNotEmpty} from "../../../../utils/array/isNotEmpty.ts"
import {usePhone} from "../../hooks/usePhone.ts"
// import {PhoneField} from "@getbybus/gbb-green";
import PhoneField from "../../../../features/inputs/features/phoneField/components/PhoneField.tsx";

const BookingContactInformation = () => {
    const nameLabel = useTranslation("booking.form.name") as string
    const surnameLabel = useTranslation("booking.form.lastName") as string
    const emailLabel = useTranslation("booking.form.email") as string
    const mobileLabel = useTranslation("booking.form.mobile") as string
    const contactInformation = useTranslation("booking.form.contactInformation") as string

    const {
        elRef,
        phone,
        countryPhone,
        displayError,
        setValidState,
        setPhoneHandler,
        errorMessagePhone,
        setCountryPhoneHandler
    } = usePhone();

    return (
        // This elRef was added since the gb-green Phone component does not have a ref prop #TODO remove this when the gb-green component is updated
        <div className={'gb--booking-item'} ref={elRef}>
            <CollapsableComponent label={contactInformation} hrClassName={'gb--divider__hr'}>
                <Row
                    justify
                    className="gb--booking__input"
                >
                    <BookingInfoInputField
                        formType={"contactInformationForm"}
                        field={"firstName"}
                        fieldValidationMethod={isNotEmpty}
                        id={"first-name"}
                        label={nameLabel}
                        icon={<IconPax />}
                        type={"text"}
                        maxLength={25}
                    />
                    <BookingInfoInputField
                        formType={"contactInformationForm"}
                        field={"lastName"}
                        fieldValidationMethod={isNotEmpty}
                        id={"last-name"}
                        label={surnameLabel}
                        icon={<IconPax />}
                        type={"text"}
                        maxLength={25}
                    />
                </Row>
                <Row
                    justify
                    className="gb--row-full_width gb--booking__contact gb--booking__input"
                >
                    <BookingInfoInputField
                        formType={"contactInformationForm"}
                        field={"email"}
                        fieldValidationMethod={validateEmail}
                        id={"email"}
                        label={emailLabel}
                        icon={<IconEmail />}
                        type={"email"}
                        maxLength={50}
                    />
                    <div className="gb--booking__container">
                        <PhoneField
                            mainClassName="gb--booking__container"
                            containerClassName="gb--labelInput gb--labelInput__white"
                            inputClassName="gb--phone__input"
                            phoneNumber={phone}
                            errorNotificationClassName="error-notification"
                            setPhoneNumber={(value) => {
                                setPhoneHandler(value, value.length > 0)
                            }}
                            setValidState={setValidState}
                            phoneCountryObject={countryPhone}
                            setPhoneCountryObject={setCountryPhoneHandler}
                            phoneInputPlaceholder={mobileLabel}
                            countrySelectPlaceholder={"Choose a country"}
                            showError={displayError}
                            errorMessage={errorMessagePhone}
                            assetsBaseUrl=""
                            assetFolder="public"
                            assetSubFolder=""
                            onBlur={() => {}}
                            sharedImagesVersion={undefined}
                            countrySelectClassName="phone-container__country"
                        />
                    </div>
                </Row>
            </CollapsableComponent>
        </div>
    )
}

export default BookingContactInformation

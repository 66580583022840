import IconType from "../../../types/Icon.type.ts";
import GenericSvgIcon from "./GenericSvgIcon.tsx";


const IconGlobeWithFlag = (props: IconType) => {
    const {
        width = '30',
        height = '30',
        viewBox = '0 0 30 30',
        title = 'IconGlobeWithFlag',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(-1 -1)">
                    <g transform="translate(0 3)">
                        <circle
                            cx="14.996098"
                            cy="14.904203"
                            fill="#fff"
                            r="11.148484"
                            transform="matrix(.42260624 -.90631339 .90631339 .42260624 -4.849225 22.196758)"
                        />
                        <circle cx="20.067568" cy="7.905405" fill="#fff" r="2.027027"/>
                        <path
                            d="m7.64189189 23.25c.79054054.6891892 1.92567568.7905405 2.81756761.2837838.3648648-.2027027.75-.445946 1.1756756-.7297297.2837838-.2027027.5270271-.4662163.7094595-.7702703.2027027-.3648649.4459459-.75.5675676-1.195946.7905405-3.0405405-1.8445946-3.1621621-1.9256757-3.831081-.0810811-.75.8918919-1.3175676 1.4797297-1.9054054.5878379-.5878379.7702703-1.5.4662162-2.0270271-.9729729-1.7432432-3.28378375-.8310811-3.50675672 1.0337838-.12162163 1.1148649-.79054054 2.25-1.35810811 2.8378378-.56756757.5675676-1.68243243.2635136-1.98648649-.5675675-.34459459-.9324325.85135135-1.277027.66891892-2.472973-.04054054-.3243243-.38513514-.4864865-.81081081-.5472973l-2.00675676.0810811c-.04054054.4662162-.08108108.9527027-.08108108 1.4391892 0 2.4932432.81081081 4.7837838 2.20945946 6.6486486 0 0 1.58108108 1.5202703 1.58108108 1.722973z"
                            fill="#7ed321"
                        />
                        <path
                            d="m15 26.6554054c-1.7027027 0-3.3851351-.3648649-4.9662162-1.1148649-2.83783785-1.3175675-5.00675677-3.6891891-6.0810811-6.6283783-1.07432432-2.9391892-.93243243-6.1216217.40540541-8.97972977 1.58108108-3.38513513 4.58108108-5.77702702 8.24999999-6.52702702.3243243-.06081082.6486487.14189189.7297297.46621621.0608108.32432433-.1418919.64864865-.4662162.72972973-3.28378376.68918919-5.97972971 2.81756757-7.39864863 5.85810815-1.19594594 2.554054-1.31756756 5.4121621-.34459459 8.0472973.9527027 2.6351351 2.89864865 4.7635135 5.45270272 5.9391891 2.554054 1.195946 5.4121621 1.3175676 8.0472973.3445946 2.6351351-.9527027 4.7635135-2.8986486 5.9391892-5.4527027.5067567-1.0945946.831081-2.25.9324324-3.4256756.0405405-.3243244.3445946-.5878379.6689189-.5472973.3243243.0405405.5878379.3243243.5472973.6689189-.1216216 1.3175676-.4864865 2.6148648-1.054054 3.8108108-1.3175676 2.8378378-3.6891892 5.0067568-6.6283784 6.0810811-1.3175676.5067567-2.6756757.7297297-4.0337838.7297297z"
                            fill="#070c63"
                        />
                    </g>
                    <g transform="matrix(.96592583 -.25881905 .25881905 .96592583 16.02152 3.971955)">
                        <path
                            d="m3.32674362 8.49822662h7.89851178l-.9113668-.89287525c-.99237707-.96155795-.99237707-2.40389488 0-3.36545284l.9113668-.89287524h-7.89851178z"
                            fill="#f5a623"
                        />
                        <path d="m1.717068 1.030241h4.80779v5.151203h-4.80779z" fill="#f5a623"/>
                        <g fill="#070c63">
                            <path
                                d="m6.60117168 6.69656433h-4.27359092c-.34595735 0-.61051298-.22321881-.61051298-.51512033s.26455563-.51512034.61051298-.51512034h3.66307793v-4.12096266h-3.66307793c-.34595735 0-.61051298-.22321881-.61051298-.51512033 0-.29190153.26455563-.51512034.61051298-.51512034h4.27359092c.34595736 0 .61051298.22321881.61051298.51512034v5.15120333c0 .29190152-.26455562.51512033-.61051298.51512033z"
                            />
                            <path
                                d="m11.304049 9.01334695h-7.36366651c-.3477287 0-.61363887-.22321881-.61363887-.51512033s.26591017-.51512033.61363887-.51512033h6.01366094l-.04090925-.03434136c-1.18636849-1.15043541-1.18636849-2.90184454 0-4.05227995l.04090925-.03434136h-1.51364255c-.34772869 0-.61363887-.22321881-.61363887-.51512033 0-.29190153.26591018-.51512034.61363887-.51512034h2.86364812c.2454555 0 .4500018.12019475.5522749.3090722.1022732.18887746.0613639.39492559-.1022731.54946169l-.9204583.89287525c-.7977305.7726805-.7977305 1.92311591 0 2.69579641l.9204583.89287524c.163637.1545361.1840917.36058423.1022731.54946169-.0818185.18887745-.3068194.29190152-.5522749.29190152z"
                            />
                            <path
                                d="m1.15 16.4838507v-15.96873037c0-.29190152-.24916667-.51512033-.575-.51512033s-.575.22321881-.575.51512033v15.96873037c.01919317.1799249.20640248.2692705.56162791.2680368.35522544-.0012681.55134947-.0906137.58837209-.2680368z"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconGlobeWithFlag;

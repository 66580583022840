

interface CountryFlagProps {
    isoCode: string,
}

function CountryFlag({isoCode}: CountryFlagProps) {
    return (
        <img
            alt={isoCode}
            src={`/public/country_flags/${isoCode.toLowerCase()}.png`}
        />
    );
}

export default CountryFlag;
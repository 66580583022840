import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {
    clearData,
    setDestinationLocation,
    setNumberOfPeople, setSingleTransferFormData,
    setStartLocation
} from "../../../pages/bookingForm/reducers/functions/bookingSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";

export default function useClearBookingFormOnNewSearch() {
    const dispatch = useAppDispatch()
    const {
        fromStation,
        toStation,
        numberOfPassengers,
        departureDate: pickUpDateTime
    } = useAppSelector((state) => (state.form))

    const clearBookingFormOnNewSearch = () => {
        dispatch(clearData())
        dispatch(setStartLocation(fromStation?.label || ''));
        dispatch(setDestinationLocation(toStation?.label || ''));
        dispatch(setNumberOfPeople(numberOfPassengers));
        dispatch(setSingleTransferFormData({
            value: pickUpDateTime,
            isValid: !!pickUpDateTime,
            displayError: false,
            type: "pickUpTime",
            errorMessage: "booking.form.timeError"
        }))
    }

    return {
        clearBookingFormOnNewSearch
    }
}
import useTranslation from "../../../../../../features/translation/hooks/useTranslation.ts";

export const useMozioVehicleObject = () => {
    const sedanName = useTranslation(`stationToStation.mozioVehicles.${1}`)
    const vanName = useTranslation(`stationToStation.mozioVehicles.${2}`)
    const minibusName = useTranslation(`stationToStation.mozioVehicles.${3}`)

    const MozioVehicleObject = {
        1: {
            typeId: 1,
            type: sedanName,
            photoUrl: "/public/executive_2.png",
            minNoOfPersons: 1,
            maxNoOfPersons: 4,
            rating: 4.8,
            modelMake: "Skoda Octavia",
            ratingCount: 342,
        },
        2: {
            typeId: 2,
            type: vanName,
            photoUrl: "/public/minivan_1.png",
            minNoOfPersons: 4,
            maxNoOfPersons: 8,
            rating: 4.5,
            modelMake: "Mercedes V class",
            ratingCount: 256,
        },
        3: {
            typeId: 3,
            type: minibusName,
            photoUrl: "/public/executive_1.png",
            minNoOfPersons: 9,
            maxNoOfPersons: 15,
            rating: 3.6,
            modelMake: "Renault Master",
            ratingCount: 98,
        },
    }

    return {
        MozioVehicleObject
    }

}

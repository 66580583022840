import HeroVectorLarge from "./HeroVectorLarge";
import HeroVectorSmall from "./HeroVectorSmall";
import useRouteMatch from "../../../../../routing/hooks/useRouteMatch.ts";
import rootExtractor from "../../../../../utils/dataset/rootExtractor.ts";

function HeroBackgroundMain() {
    const isHomepage = useRouteMatch('homepage');
    const companyStore = rootExtractor('companyStoreData', true);

    if (companyStore?.companyHeroImage) {
        return (
            <>
                <picture>
                    <img
                        typeof="image/jpeg"
                        src={companyStore?.companyHeroImage}
                        alt="City landscape"
                    />
                </picture>
                {/*{isBig && (*/}
                {/*    <h1 className="gb--title-light">*/}
                {/*        <Translation translationKey="form.find_your"/>*/}
                {/*    </h1>*/}
                {/*)}*/}
            </>
        )
    }

    if (isHomepage) {
        return <HeroVectorLarge/>;
    }

    return <HeroVectorSmall />;
}

export default HeroBackgroundMain

import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useEffect} from "react";
import usePlacePlaceholder from "./usePlacePlaceholder.ts";
import {useToggle} from "@getbybus/gbb-green";

interface UsePlaceSelectProps {
    name: "departure" | "destination",
    isOverlay?: boolean,
    searchStrict?: boolean,
    direction: "from" | "to",
    data?: any,
    toRef?: HTMLInputElement,
    inputRef: HTMLInputElement
}

export const usePlaceSelect = (props: UsePlaceSelectProps) => {

    const {
        toPlace,
        toStation,
        fromPlace,
        formErrors,
        fromStation,
        departureDate,
        submitTimestamp,
    } = useAppSelector((state) => (state?.form));

    const {
        name,
        direction,
    } = props

    const {
        set: setIsDropdownOpen,
        value: isDropdownOpen,
    } = useToggle();

    const error = direction === 'from' ? formErrors.departureError : formErrors.destinationError;
    const errorMessage = direction === 'from' ? 'search.form.departureError' : 'search.form.destinationError';

    const {
        placePlaceholder,
        setPlacePlaceholder,
    } = usePlacePlaceholder(name);


    const handleClickOutside = () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false)
        }
    }

    return {
        setIsDropdownOpen,
        handleClickOutside,
        setPlacePlaceholder,
        error,
        toPlace,
        fromPlace,
        toStation,
        fromStation,
        errorMessage,
        departureDate,
        isDropdownOpen,
        submitTimestamp,
        placePlaceholder,
    }
}
import {useEffect} from "react";
import PhoneCountryType from "../../../../../types/PhoneCountry.type.ts";
import getCountryBasedOnNumberSetter from "../functions/getCountryBasedOnNumberSetter.ts";
import getAppendCountryCode from "../functions/getAppendCountryCode.ts";
import {isFirstCharacterZero} from "../functions/isFirstCharacterZero.ts";
import usePrevious from "../../../../../hooks/refs/usePrevious.ts";


interface UseUpdatePhoneNumberAttributes {
    phoneNumber: string,
    setPhoneNumber: (value: string) => void,
    phoneCountryObject: PhoneCountryType,
    setPhoneCountryObject: (value: PhoneCountryType) => void,
    closePhoneCountrySelect: () => void,
    setValidState: () => void,
}

export default function useUpdatePhoneNumber(attributes: UseUpdatePhoneNumberAttributes) {
    const {
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        closePhoneCountrySelect,
        setValidState,
    } = attributes;

    const previousDialCode = usePrevious(phoneCountryObject?.dialCode);
    const previousPhone = usePrevious(phoneNumber || '');
    const setCountryBasedOnNumber = getCountryBasedOnNumberSetter(setPhoneCountryObject, closePhoneCountrySelect);
    const appendCountryCode = getAppendCountryCode(setPhoneNumber);

    useEffect(() => {
        const dialCode = phoneCountryObject?.dialCode;

        if (dialCode !== previousDialCode && dialCode) {
            appendCountryCode(dialCode, previousDialCode, phoneNumber);
            setValidState()
        }


        if (phoneNumber && phoneNumber?.length > 1 && !isFirstCharacterZero(phoneNumber) && (!previousPhone || previousPhone.length <= phoneNumber.length)) {
            setCountryBasedOnNumber(phoneNumber);
        }
    }, [phoneCountryObject?.dialCode, phoneNumber])

    // useEffect(() => {
    //     const dialCode = phoneCountryObject?.dialCode
    //     if (!phoneNumber?.length || (previousDialCode?.length > 0 && dialCode?.length - 1 >= phoneNumber?.length) || !phoneNumber.includes(dialCode)) {
    //         setPhoneCountryObject({
    //             name: "",
    //             dialCode: "",
    //             isoCode: "",
    //         })
    //         closePhoneCountrySelect()
    //     }
    // }, [phoneNumber])
}
import PhoneCountryType from "../../../../../types/PhoneCountry.type.ts";
import {matchLongestValidDialCode} from "./matchLongestValidDialCode.ts";


export default function getCountryBasedOnNumberSetter(setPhoneCountryObject: (value: PhoneCountryType) => void, closePhoneCountrySelect: () => void) {
    const countryBasedOnNumber = (phoneNumber) => {
        const result = matchLongestValidDialCode(phoneNumber)
        if (result) {
            closePhoneCountrySelect()
            setPhoneCountryObject(result)
        }
    }
    return countryBasedOnNumber
}
import Card from "../Card.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import LinePlaceholder from "../../../features/placeholders/components/LinePlaceholder.tsx";

const VehicleCardLoader = () => (
    <Card additionalClass='gb--vehicle gb--vehicle-loader gb--card-enlarge__small'>
        <Row gap style={{gap: '3rem'}}>
            <Column justify style={{gap: '7rem'}}>
                <Row>
                    <LinePlaceholder height='30px'/>
                </Row>
                <Row>
                    <LinePlaceholder height='90px'/>
                </Row>
            </Column>
            <Column style={{gap: '8rem'}}>
                <Column style={{gap: '1rem'}}>
                    <LinePlaceholder height='20px'/>
                    <LinePlaceholder height='20px'/>
                    <LinePlaceholder height='20px'/>
                </Column>
                <Column>
                    <LinePlaceholder height='30px'/>
                </Column>
            </Column>
        </Row>
    </Card>
);

export default VehicleCardLoader;